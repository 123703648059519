<!-- <div class="d-flex h-90">
  <div class="login-container m-auto">
    <div class="card boxshadow">
      <div class="card-header bg-primary dark text-white clearfix">
        <i class="fa fa-lock" aria-hidden="true"></i> Login
        <button *ngIf="isModal" type="button" class="close float-right text-light" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="card-body">
        <div class="col-md-8 offset-md-2">
          <form *ngIf="formResetToggle" class="login" name="loginForm" #f="ngForm" novalidate
                (ngSubmit)="f.form.valid ? login() :
                          (!username.valid && showErrorAlert('Username is required', 'Please enter a valid username'));
                          (!password.valid && showErrorAlert('Password is required', 'Please enter a valid password'))">
            <div class="form-group row">
              <label class="col-form-label col-md-3" for="login-username">Username:</label>
              <div class="col-md-9">
                <input type="text" id="login-username" name="username" placeholder="Enter username" autocomplete="username" class="form-control" [ngClass]="{'is-valid': f.submitted && username.valid, 'is-invalid' : f.submitted && !username.valid}"
                       [(ngModel)]="userLogin.userName" #username="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !username.valid">
                  Username is required
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-3" for="login-password">Password:</label>
              <div class="col-md-9">
                <input type="password" id="login-password" name="password" placeholder="Enter password" autocomplete="current-password" class="form-control" [ngClass]="{'is-valid': f.submitted && password.valid, 'is-invalid' : f.submitted && !password.valid}"
                       [(ngModel)]="userLogin.password" #password="ngModel" required />
                <span class="invalid-feedback" *ngIf="f.submitted && !password.valid">
                  Password is required
                </span>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-3 col-md-9">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="login-rememberme" name="rememberMe" [(ngModel)]="userLogin.rememberMe">
                  <label class="form-check-label" for="login-rememberme">Remember me</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-3 col-md-9">
                <button type="submit" class="btn btn-primary" [disabled]="isLoading">
                  <i *ngIf="isLoading" class='fa fa-circle-o-notch fa-spin'></i> {{isLoading ? 'Logging in...' : 'Login'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
    <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
      <!-- <img *ngIf="!isModal" src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="28" /> -->
      <!-- <h2 class="brand-text text-primary ml-1">Trackup Solutions</h2> -->
      <!-- <h1>LOGO HERE</h1> -->

      <img src="assets/images/logo/riverlong.jpg" alt="Logo" />
      <!-- <img
        height="180"
        width="200"
        src="assets/images/logo/smatfast.jpeg"
        alt="Logo"
      /> -->
    </a>
    <!-- /Brand logo-->
    <!-- Left Text-->

    <!-- /Left Text-->
    <!-- Login-->
    <div class="d-flex col-md-3 align-items-center auth-bg px-2 p-lg-2">
      <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
        <!-- <h2 class="card-title font-weight-bold mb-1">Tracking Demo</h2> -->
        <!-- <p class="card-text mb-2">Please sign-in to your account</p> -->

        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
          <div class="alert-body">
            <p>{{ error }}</p>
          </div>
        </ngb-alert>

        <form
          class="auth-login-form mt-2"
          [formGroup]="loginForm"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-group">
            <label class="form-label" for="login-email">Email</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              placeholder="email"
              aria-describedby="login-email"
              autofocus=""
              tabindex="1"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <!-- <div *ngIf="f.email.errors.email">Email must be a valid email address</div> -->
            </div>
          </div>
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="login-password">Password</label
              ><a><small>Forgot Password?</small></a>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
              <input
                [type]="passwordTextType ? 'text' : 'password'"
                formControlName="password"
                class="form-control form-control-merge"
                [ngClass]="{
                  'is-invalid error': submitted && f.password.errors
                }"
                placeholder="············"
                aria-describedby="login-password"
                tabindex="2"
              />
              <div class="input-group-append">
                <span
                  class="input-group-text cursor-pointer"
                  style="border-radius: 1px"
                  ><i
                    class="feather font-small-4"
                    [ngClass]="{
                      'icon-eye-off': passwordTextType,
                      'icon-eye': !passwordTextType
                    }"
                    (click)="togglePasswordTextType()"
                  ></i
                ></span>
              </div>
            </div>
            <div
              *ngIf="submitted && f.password.errors"
              class="invalid-feedback"
              [ngClass]="{ 'd-block': submitted && f.password.errors }"
            >
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                class="custom-control-input"
                id="remember-me"
                type="checkbox"
                tabindex="3"
              />
              <label class="custom-control-label" for="remember-me">
                Remember Me</label
              >
            </div>
          </div>
          <button
            [disabled]="isLoading"
            class="btn btn-primary btn-block"
            tabindex="4"
            rippleEffect
            style="border-radius: 2px"
          >
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span
            >SIGN IN
          </button>
        </form>
      </div>
    </div>

    <div
      class="d-none d-lg-flex col-md-9 align-items-center"
      style="padding: 0"
    >
      <!-- <img
        src="assets/images/logo/smart_logo.v1.jpg"
        alt="BackgroundImage"
        style="
          background-size: cover;
          background: no-repeat center center fixed;
          height: 100% !important;
          width: 100% !important;
        "
      /> -->

      <img
        src="assets/images/logo/hongkong-1.jpg"
        alt="BackgroundImage"
        style="
          background-size: cover;
          background: no-repeat center center fixed;
          height: 100% !important;
          width: 100% !important;
        "
      />

      <!-- <img
        src="assets/images/logo/hongkong-1.jpg"
        alt="BackgroundImage"
        style="
          background-size: cover;
          background: no-repeat center center fixed;
          height: 100% !important;
          width: 100% !important;
        "
      /> -->

      <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"> --
         <img class="img-fluid" [src]="
              coreConfig.layout.skin === 'dark'
                ? 'assets/images/pages/login-v2-dark.svg'
                : 'assets/images/pages/login-v2.svg'
            " alt="Login V2" /> -->
      <!-- </div> -->
    </div>
  </div>
</div>
